<template>
  <div class="rightLink">
    <div class="bottomDiv1" style="width: 97%; height: auto">
      <div class="title">故障统计</div>
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="list3"
          border
          v-loading="loading"
          height="600px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (curentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>

          <!-- <el-table-column label="id" prop="" /> -->
          <el-table-column label="充电站名称" prop="station_name" width="150" />
          <el-table-column label="充电桩编号" prop="device_id" width="130" />
          <el-table-column label="位置" prop="" width="130">
            <template #default="scope">
              <div>
                {{
                  scope.row.province +
                  scope.row.city +
                  scope.row.county +
                  scope.row.community
                }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="故障类型" prop="status" />
          <el-table-column label="详细情况" prop="details" />
          <!-- <el-table-column label="运行状态" prop="status" /> -->

          <el-table-column label="申报时间" prop="create_time" width="160" />
          <!-- <el-table-column label="处理人" prop="actoruser" /> -->
          <!-- <el-table-column label="处理方式" prop="process_mode" /> -->
          <el-table-column label="所属运营商" prop="username" />
          <el-table-column label="操作" width="120" align="center">
            <template #default="scope">
              <el-button
                v-show="scope.row.process_mode == null"
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="detail(scope.$index, scope.row)"
                >处理</el-button
              >
              <!-- <div v-show="scope.row.is_return == 1" class="BtnSearch1 btnclose" @click="handleCl(scope.$index, scope.row)">关闭退费</div> -->
              <el-button
                style="margin: 0 !important"
                v-show="scope.row.process_mode != null"
                round
                type="warning"
                plain
                size="mini"
                @click="detail(scope.$index, scope.row)"
                >继续处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>

  <!-- 弹窗 -->
  <div v-dialogdrag>
    <el-dialog
      v-model="Dialog.editLevy"
      title="故障处理"
      width="920px"
      :before-close="closeDialog"
      class="dialog"
    >
      <div class="formDiv">
        <el-form
          ref="FormRules"
          :rules="rules"
          :model="FormData"
          label-position="right"
          label-width="140px"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="充电站名称:">
                {{ Dialog.DialogData.station_name }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="充电站位置:">
                {{
                  Dialog.DialogData.province +
                  Dialog.DialogData.city +
                  Dialog.DialogData.county +
                  Dialog.DialogData.community
                }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="充电桩编号:">
                {{ Dialog.DialogData.device_id }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="故障编号:">
                <el-input disabled v-model="Dialog.DialogData.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="故障情况">
                <el-input
                  disabled
                  v-model="Dialog.DialogData.details"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- <el-col :span="3"></el-col> -->
            <el-col :span="10">
              <el-form-item label="更换配件:">
                <el-radio-group
                  v-model="Dialog.DialogData.change_accessory"
                  @change="radio1"
                >
                  <el-radio :label="0" name="0">需要</el-radio>
                  <el-radio :label="1" name="1">不需要</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-show="isshow">
              <el-form-item label="配件详情:">
                <el-input
                  v-model="Dialog.DialogData.accessories_data"
                  placeholder="请输入配件"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- <el-col :span="3"></el-col> -->
            <el-col :span="10">
              <el-form-item label="处理方式:">
                <el-input
                  v-model="Dialog.DialogData.process_mode"
                  placeholder="请输入处理方式"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="处理人:">
                <el-input
                  v-model="Dialog.DialogData.actoruser"
                  placeholder="请输入处理人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- <el-col :span="3"></el-col> -->
            <el-col :span="10">
              <el-form-item label="备注:">
                <el-input
                  v-model="Dialog.DialogData.updetails"
                  placeholder="备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="Btn" @click="closeDialog">取消</el-button>
          <el-button class="Btn" type="primary" @click="submitDialog1"
            >保存</el-button
          >
          <el-button class="Btn" type="success" @click="submitDialog2"
            >处理完成</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <style scoped >
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 20px;
}
.UserHome {
  padding: 2px 30px 2px 2px;
}

.card {
  padding: 2%;
}
.card > p {
  margin-top: 5px;
}
</style>
  
  <script>
// import { onMounted, reactive, toRefs, onBeforeUnmount, ref } from "vue-demi";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  fault_message,
  processing_failure1,
  processing_failure2,
} from "@/request/api";
import { ElMessage } from "element-plus";
// import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "News",
  // components: {
  //   Vue3SeamlessScroll,
  // },
  setup() {
    const data = reactive({
      change_accessory: "",
      isshow: false,
      list: [
        // }
      ],
      list2: [],
      list3: [],

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      total: 0,
      curentPage: 1,
      pageSize: 20,
    });

    const getList = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        work_type: 0,
        page: data.curentPage,
      };
      fault_message(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.list3 = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const radio1 = (a) => {
      // data.FormData.type = a
      if (a == "0") {
        data.isshow = true;

        console.log("000000");
      } else if (a == "1") {
        data.isshow = false;
        console.log("111111");
      }
    };
    const detail = (index, row) => {
      console.log(row, "rowww");
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog.DialogData = {};
    };
    const submitDialog1 = () => {
      console.log("故障处理");
      const dataa = {
        id: data.Dialog.DialogData.id,
        change_accessory:
          data.Dialog.DialogData.change_accessory == 0 ? "需要" : "不需要",
        accessories_data: data.Dialog.DialogData.accessories_data,
        process_mode: data.Dialog.DialogData.process_mode,
        actoruser: data.Dialog.DialogData.actoruser,
        updetails: data.Dialog.DialogData.updetails,
      };
      processing_failure1(dataa).then((res) => {
        if (res) {
          if (res.code == 200) {
            ElMessage.success("保存成功");
            closeDialog();
            getList();
          }
        } else {
          ElMessage.error("保存失败");
        }
      });
    };
    const submitDialog2 = () => {
      console.log("故障处理");
      const dataa = {
        id: data.Dialog.DialogData.id,
        change_accessory: data.Dialog.DialogData.change_accessory,
        accessories_data: data.Dialog.DialogData.accessories_data,
        process_mode: data.Dialog.DialogData.process_mode,
        actoruser: data.Dialog.DialogData.actoruser,
        updetails: data.Dialog.DialogData.updetails,
      };
      processing_failure2(dataa).then((res) => {
        if (res) {
          ElMessage.success("操作成功");
          closeDialog();
          getList();
        } else {
          ElMessage.error("操作失败");
        }
      });
    };
    const handleCurrentChange = (val) => {
      console.log(111);
      data.curentPage = val;
      getList();
    };
    onMounted(() => {
      getList();
    });

    return {
      ...toRefs(data),
      detail,
      getList,
      radio1,
      closeDialog,
      submitDialog1,
      submitDialog2,
      handleCurrentChange,
    };
  },
};
</script>
  